/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import Header from "./header"
import Seo from "./seo"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      wp {
        allSettings {
          generalSettingsDescription
          generalSettingsTitle
        }
      }
    }
  `)

  return (
    <>
      <Seo title={data.wp.allSettings.generalSettingsTitle} />
      <div className="bg-white">
        <main>
          <div>
            <Header />
            {children}
            <Footer />
          </div>
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
