import React from "react"
import Layout from "../components/layout"
import NewsItem from "../components/news/news-item"
import { graphql } from "gatsby"
import Link from "gatsby-link"
import NewsItemFeatured from "../components/news/news-item-featured"

export default function LatestNews({
  data,
  pageContext: { nextPagePath, previousPagePath, projectData, projects },
}) {
  const title = projectData?.title || ""
  const allPosts = data.allWpPost.nodes
  const posts = !previousPagePath
    ? allPosts.filter((drink, index) => index !== 0)
    : allPosts
  const featuredPost = previousPagePath ? null : (
    <NewsItemFeatured post={allPosts[0]} />
  )
  const prevPage = previousPagePath ? (
    <>
      <li className="m-4">
        <Link to={previousPagePath} rel="prev">
          ← Previous
        </Link>
      </li>
    </>
  ) : (
    <li>&nbsp;</li>
  )
  const selected = projectData?.slug || "all"

  function gotoNews(e) {
    const selectedIndex = e.target.options.selectedIndex

    window.location.href =
      selectedIndex === 0
        ? "/latest-news"
        : "/" + e.target.options[selectedIndex].value
    return undefined
  }

  return (
    <>
      <Layout>
        <div className="bg-white overflow-hidden mx-4">
          <div className="relative max-w-lg mx-auto divide-gray-200 lg:max-w-7xl">
            <div className="md:flex mb-3 align-middle items-center mt-4 md:mt-8">
              <h1 className="md:w-1/2 text-xl w-full md:text-3xl ">
                {title} Latest News
              </h1>
              <select
                onChange={e => gotoNews(e)}
                defaultValue={selected}
                className="h-10 w-full  md:w-1/2 border-solid border border-gray-200 mt-3 md:mt-0 "
              >
                <option value="all">All News</option>
                {projects.map(project => {
                  return (
                    <option
                      value={project.project.slug}
                      key={project.project.slug}
                    >
                      {project.project.title} News
                    </option>
                  )
                })}
              </select>
            </div>
            <hr />
            {featuredPost}
            <div className="mt-6 grid gap-x-6 gap-y-8 grid-cols-2 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-4">
              {posts.map(post => {
                return <NewsItem post={post} key={post.title} />
              })}
            </div>

            <nav className="my-4 md:my-8">
              <ul className="flex justify-between items-center">
                {prevPage}
                <li className="m-4">
                  {nextPagePath && <Link to={nextPagePath}>Next →</Link>}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        id
        title
        slug
        content
        excerpt
        date(formatString: "MMMM DD, YYYY")
        project_category {
          project {
            ... on WpProject {
              id
              title
            }
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
