import React from "react"
//

export default function PostCategory({ project }) {
  return (
    <>
      <div key={project.id} className="hidden md:block">
        <a href={project.id} className="inline-block">
          <span className="bg-green-100 text-green-800 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium mb-4">
            {project.title}
          </span>
        </a>
      </div>
    </>
  )
}
