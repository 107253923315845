import React from "react"
import PostCategory from "../post-category"
import Image from "gatsby-image"

export default function NewsItem({ post, hideCategory }) {
  const category = hideCategory ? null : (
    <PostCategory project={post.project_category.project} />
  )
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }
  return (
    <>
      <div key={post.title} className="border-t border-gray-400 md:border-0">
        <a href={`/latest-news/${post.slug}`} className="hidden md:block mt-4">
          {featuredImage?.fluid && (
            <Image
              className="mt-2 h-48 md:h-80 mb-5 hidden md:block"
              fluid={featuredImage.fluid}
              alt={featuredImage.alt}
            />
          )}
        </a>
        {category}
        <a href={`/latest-news/${post.slug}`} className="block mt-8 md:mt-0">
          <p className="font-light text-gray-600 text-sm mb-2">{post.date}</p>
          <p className="text-sm md:text-xl font-semibold">{post.title}</p>

          {/*<p className="news-item mt-3 text-base text-gray-800 hidden md:block" dangerouslySetInnerHTML={{__html: post.excerpt}} />*/}
          {/*<div className="mt-3 hidden md:block">*/}
          {/*  <span className="text-base font-semibold text-green-600 hover:text-green-500">*/}
          {/*    Read full story*/}
          {/*  </span>*/}
          {/*</div>*/}
        </a>
      </div>
    </>
  )
}
