import React from "react"
import PostCategory from "../post-category"
import Image from "gatsby-image"

export default function NewsItemFeatured({ post }) {
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }
  return (
    <>
      <div key={post.title} className="block md:flex md:-mb-10">
        <a
          href={`/latest-news/${post.slug}`}
          className="block mt-4 w-full md:w-2/3 pr-2 "
        >
          {featuredImage?.fluid && (
            <Image
              className="mt-2 h-64 md:h-102 mb-5"
              fluid={featuredImage.fluid}
              alt={featuredImage.alt}
            />
          )}
        </a>
        <a
          href={`/latest-news/${post.slug}`}
          className="block md:mt-8 w-full md:w-1/3 md:pl-3"
        >
          <p className="text-sm md:text-3xl font-semibold">{post.title}</p>
          <p className="font-light text-gray-600 text-sm my-2 hidden">
            {post.date}
          </p>

          <p
            className="news-item mt-3 text-base text-gray-800 hidden md:block"
            dangerouslySetInnerHTML={{ __html: post.excerpt }}
          />
          <div className="mt-3 md:block">
            <span className="text-base font-semibold text-green-600 hover:text-green-500">
              Read full story →
            </span>
          </div>
        </a>
      </div>
    </>
  )
}
